import { CircleX, FileCheck2 } from "lucide-react";
import FullscreenModalWrapper from "../../../../../../components/modal/wrapper/fullscreen-modal-wrapper";
import InputField from "../../../../../../components/form/input-field";
import ToggleSwitch from "../../../../../../components/form/toggle-switch";
import TextareaField from "../../../../../../components/form/textarea";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import ApiRequest from "../../../../../../lib/ApiCall";
import { useEffect, useState } from "react";
import { EmptyFormInput } from "../../../../../../lib/helper-support";
import { ErrorFlash, SuccessFlash } from "../../../../../../lib/flash-notification";
import SpinnerLoader from "../../../../../../components/loader/spinner-loader";

interface PaymentType{
    name: string,
    amount: string,
    description: string,
    duration: string,
    isRecurringPayment: boolean,
    isOnboardPayment: boolean,
    uuid: string,
}

interface FormType {
    openModal: boolean,
    onSuccess: (data:any) => void,
    isEdit?: boolean,
    onCloseModal: () => void,
    data?:PaymentType,
}
const PaymentTypeForm = ({
    openModal,
    onSuccess,
    onCloseModal,
    isEdit = false,
    data,
} : FormType) => {

    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const formSchema = yup.object({
        name: yup.string().required(),
        amount: yup.string().required(),
        description: yup.string().nullable(),
        duration: yup.string().nullable(),
        isRecurringPayment: yup.boolean().default(false),
        isOnboardPayment: yup.boolean().default(false),
    });
    
    const {
        setValue,
        setError,
        formState: {errors},
        handleSubmit,
        getValues,
        reset,
    } = useForm({resolver: yupResolver(formSchema)}); 

    useEffect(()=> {
        reset(EmptyFormInput(formSchema));
        data && reset(data);
    }, [openModal]);

    const onSubmit = async (data:any) => {
        let request;
        setLoadingSubmit(true);
        if ( isEdit ){
            data['paymentTypeId'] = data.uuid;
            request = await ApiRequest({endpoint: 'payment-type/update', method: 'POST', payload: data});
        }else{
            request = await ApiRequest({endpoint: 'payment-type', method: 'POST', payload: data});
        }

        setLoadingSubmit(false);

        if ( request.status === '200' ){
            onSuccess(request.data);
            SuccessFlash(isEdit ? 'Payment Type updated successfully' : 'Payment Type created successfully');
            return;
        }

        ErrorFlash(request.message);

        if (request.status === "payloadValidationError") {
            for (const [field, messages] of Object.entries(request.data)) {
                const input_field : any = field ;
                const error_message : any = messages
                setError(input_field, { type: "manual", message: error_message.join(", ") });
               // SmoothScroll();
            }
        }
        
    }

    return (
        <div>
            {openModal && 
            <FullscreenModalWrapper>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <button onClick={onCloseModal} className="absolute top-3 right-5">
                        <CircleX size={30}/>
                    </button>
                    <div className="flex items-center justify-between mt-10">
                        <h2>{isEdit ? `Edit Payment Type: ${data?.name}` : 'Add Payment Type'}</h2>
                        <div className="flex space-x-5">
                            
                            <button disabled={loadingSubmit} className={`${loadingSubmit && 'opacity-[0.5]'} pryBtn w-fit`}>
                                <FileCheck2 size={18}/>
                                <span>{isEdit ? 'Update' : 'Submit'}</span>
                                {loadingSubmit && <SpinnerLoader/>}
                            </button>
                        </div>
                    </div>
                    <div className="grid gap-4 mt-5">
                        <InputField
                            label="Name"
                            error={errors.name?.message}
                            onChangeInput={(e) => [setValue('name', e.target.value), setError('name', {message: ''})]}
                            value={getValues('name')}
                        />
                        <InputField
                            label="Amount"
                            type="number"
                            error={errors.amount?.message}
                            onChangeInput={(e) => [setValue('amount', e.target.value), setError('amount', {message: ''})]}
                            value={getValues('amount')}
                        />
                        <InputField
                            label="Duration (in days)"
                            type="number"
                            placeholder="Enter duration"
                            error={errors.duration?.message}
                            onChangeInput={(e) => [setValue('duration', e.target.value), setError('duration', {message: ''})]}
                            value={getValues('duration') ?? ''}
                        />
                        <TextareaField
                            label="Description"
                            error={errors.description?.message}
                            onChangeInput={(e) => [setValue('description', e.target.value), setError('description', {message: ''})]}
                            value={getValues('description') ?? ''}
                        />
                        <div className="flex items-center mt-5 space-x-2">
                            <ToggleSwitch
                                initialState={getValues('isRecurringPayment') ?? false}
                                onToggle={(state) => {setValue('isRecurringPayment', state); setError('isRecurringPayment', {message: ''})}}
                            />
                            <span className="label">Is a recurring payment?</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <ToggleSwitch
                                initialState={getValues('isOnboardPayment')}
                                onToggle={(state) => {setValue('isOnboardPayment', state); setError('isOnboardPayment', {message: ''})}}
                            />
                            <span className="label">Payment on registration?</span>
                        </div>
                    </div>
                </form>
            </FullscreenModalWrapper>}
        </div>
    );
}

export default PaymentTypeForm