import { useEffect, useState } from "react";
import PaginationType from "../../../../types/pagination-type";
import ApiRequest from "../../../../lib/ApiCall";
import { numberWithCommas } from "../../../../lib/helper-support";
import moment from "moment";

interface MemberPaymentType {
    memberId: string
}

const MemberPayment = ({memberId}: MemberPaymentType) => {

    interface PaymentType{
        reference: string
        name:string,
        description:string,
        amount:string,
        paymentType:string,
        startAt:string,
        paymentTypeId:string,
        dueAmount:string,
    }

    const [payments, setPayments] = useState<PaymentType[]>([]);
    const [pageData, setPageData] = useState<PaginationType>({} as PaginationType);
    const [page, setPage] = useState<number>(1);

    const getPayments = async () => {
        const request = await ApiRequest({endpoint: `member/${memberId}/payments?page=${page}`, method: 'GET'});
        request.status === '200' && setPayments(request.data.items);
        request.status === '200' && setPageData(request.data);
    }

    useEffect(() => {
        getPayments();
    }, [page]);

    return (
        <div>
            <h4 className="font-bold underline label">Payment histories</h4>
                <div className="relative mt-2 overflow-x-auto">
                <table className='text-tertiary font-[500] text-[14px] w-full relative z-10'>
                    <thead>
                        <tr className='border-b border-[#E6EAF0] bg-[#fafafa]'>
                            <td className="px-4 py-3">Date</td>
                            <td className="px-6 py-3">Payment Type</td>
                            <td className="px-6 py-3">Amount</td>
                        </tr>
                    </thead>
                    <tbody className="relative">
                        {payments.map((payment, i) => (
                            <tr key={i} className='border-b border-[#E6EAF0]'>
                            <td className="px-4 py-3">{moment(payment.startAt).format('DD MMM, YYYY')}</td>
                            <td className="px-6 py-3">{payment.name}</td>
                            <td className="px-6 py-3">NGN {numberWithCommas(payment.amount)}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                </div>
        </div>
    );
}

export default MemberPayment