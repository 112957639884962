import { Check, CircleX, EllipsisVertical, Eye, FileCheck2, FilePenLine, ScanEye, Trash2 } from "lucide-react";
import FullscreenModalWrapper from "../../../components/modal/wrapper/fullscreen-modal-wrapper";
import { useEffect, useState } from "react";
import InputField from "../../../components/form/input-field";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import SelectField from "../../../components/form/select-field";
import ApiRequest from "../../../lib/ApiCall";
import { CurrencyTypes, DurationTypes } from "../../../lib/helper-support";
import SpinnerLoader from "../../../components/loader/spinner-loader";

type ProductType = {
    status: string;
    issuer: string;
    type: string;
    amount: string|number,
    interestRate: string|number,
    series: string,
    offerOpenDate:string,
    offerCloseDate:string,
    issuingHouses:string[],
    tenor: string,
    tenorIn: string,  
    amountCurrency: string,
    postedBy: string,
    uuid: string,
    isApproved: boolean,
    typeId: string,
}

interface EditProductType {
    onEditProduct: (data:any) => void,
    product: ProductType,
    showEditProductModal: boolean,
    onCloseModal: () => void
}

const EditProduct = ({onEditProduct, product, showEditProductModal = false, onCloseModal} : EditProductType) => {

    type OptionType = {
        value: string,
        name: string
      }
    
    const [productTypes,  setProductTypes] = useState<OptionType[]>([]);

    const [issuingHouses, setIssuingHouses] = useState<string[]>([]);
    const [issuingHouse, setIssuingHouse] = useState<string>('');
    const [onEditIssuingHouse, setOnEditIssuingHouse] = useState<null|number>(null);

    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const formSchema = yup.object({
        issuer: yup.string().required(),
        amount: yup.string().required(),
        interestRate: yup.string().required('Interest rate is required'),
        tenor: yup.string().required(),
        productTypeId: yup.string().required('Product type is required'),
        offerOpenDate: yup.string().required('Offer open date is required'),
        offerCloseDate: yup.string().required('Offer close date is required'),
        issuingHouses: yup.array().of(yup.string()),
        series: yup.string().required(),
        amountCurrency: yup.string().required('This field is required'),
        tenorIn: yup.string().required('Tenor duration is required'),
        offerPrice: yup.string().required('Offer price is required'),
        offerPriceCurrency: yup.string().required('This field is required'),
        rightRatio: yup.string().required('Right ratio is required'),
    });
    
    const  {
        setValue,
        setError,
        formState: {errors},
        handleSubmit,
        getValues,
        reset,
    } = useForm({resolver: yupResolver(formSchema)}); 

    const getProduct = () => {
        const inputs : any = product;
        reset(inputs);
        setValue('productTypeId', product.typeId);
        setIssuingHouses(product.issuingHouses);
    }

     useEffect(()=> {showEditProductModal && getProduct()}, [showEditProductModal]);

    const getProductTypes = async () => {
        const request = await ApiRequest({endpoint: '/product-types', method: 'GET'});
        request.status === '200' && setProductTypes(request.data.map((item: any) => ({value: item.uuid, name: item.name}))); 
      }

    
        
    const onSubmit = async (data:any) => {
        data['issuingHouses'] = issuingHouses;
        data['productId'] = product.uuid;
        setLoadingSubmit(true)
        const request = await ApiRequest({endpoint:`/product/update`, method:'POST', payload: data});
        setLoadingSubmit(false)
        if (request.status !== '200') return;
        onEditProduct(request.data);
        //onCloseModal();
    }

    useEffect(()=> {getProductTypes()}, []);

    const handleKeyDown = (event:React.KeyboardEvent<HTMLInputElement>) => {
    
        event.key === 'Enter' && event.preventDefault();
        
        if ( onEditIssuingHouse !== null && event.key === 'Enter' ) {
          updateIssuingHouse(onEditIssuingHouse?? 0);  
          return;
        }
        event.key === 'Enter' && addNewIssuingHouse();
      };
    
      const addNewIssuingHouse = () => {
        if ( issuingHouse === '' ) return;
        setIssuingHouses([...issuingHouses, issuingHouse]);
        setIssuingHouse('');
      }
    
      const updateIssuingHouse = (index:number) => {
        if ( issuingHouse === '' ) return;
        issuingHouses[index] = issuingHouse;
        setIssuingHouses([...issuingHouses]);
        setIssuingHouse('');
        setOnEditIssuingHouse(null);
      }
    return (
        <div>
            
            {showEditProductModal && <FullscreenModalWrapper>
            <form onSubmit={handleSubmit(onSubmit)}  className="text-left ">
            <button onClick={onCloseModal} className="absolute top-3 right-5" type="button">
                <CircleX size={30}/>
            </button>
            <div className="items-center justify-between mt-10 space-y-5 lg:flex lg:space-y-0">
                    <div className="flex items-center space-x-2">
                        <h1 onClick={() => setOnEditIssuingHouse(0)} className="text-sm page-title">Edit:  {product.issuer}</h1>
                    </div>
                    <div className="justify-end mt-5 lg:flex lg:mt-0">
                        <div className="items-center space-y-2 label lg:flex lg:space-x-4 lg:space-y-0">
                            <div className="flex items-center space-x-4">
                                <button disabled={loadingSubmit} className={`${loadingSubmit&& 'opacity-[0.5]'} pryBtn w-fit`}>
                                    <FileCheck2 size={18}/>
                                    <span>Update product</span>
                                    {loadingSubmit && <SpinnerLoader/>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid gap-4 mt-10 lg:grid-cols-2">
                    <div>
                        <InputField
                            placeholder='Enter company name'
                            label='Issuer'
                            error={errors.issuer?.message}
                            onChangeInput={(e) => [setValue('issuer', e.target.value), setError('issuer', {message: ''})]}
                            value={getValues('issuer')}
                        />
                    </div>
                    <div>
                        <SelectField
                            label='Product Type'
                            placeholder='Select'
                            error={errors.productTypeId?.message}
                            onChangeInput={(e) => [setValue('productTypeId', e.target.value), setError('productTypeId', {message: ''})]}
                            value={getValues('productTypeId')}
                            options={productTypes}
                        />
                    </div>
                    <div className='flex w-full space-x-5'>
                  <div className='w-2/3'>
                            <InputField
                            placeholder='Enter figure'
                            type='number'
                            label='Amount'
                            error={errors.amount?.message}
                            onChangeInput={(e) => [setValue('amount', e.target.value), setError('amount', {message: ''})]}
                            value={getValues('amount')}
                            />
                        </div>
                        <div className='w-1/3'>
                            <SelectField
                            placeholder='Select'
                            label='currency'
                            labelClass="opacity-0"
                            options={CurrencyTypes}
                            error={errors.amountCurrency?.message}
                            onChangeInput={(e) => [setValue('amountCurrency', e.target.value), setError('amountCurrency', {message: ''})]}
                            value={getValues('amountCurrency') ?? 'NGN'}
                            />
                        </div>
                    </div>
                    <div>
                        <InputField
                            placeholder='Enter figure'
                            label='Interest rate or Yield'
                            type='number'
                            error={errors.interestRate?.message}
                            onChangeInput={(e) => [setValue('interestRate', e.target.value), setError('interestRate', {message: ''})]}
                            value={getValues('interestRate')}
                        />
                    </div>

                    <div>
                        <InputField
                            label='Right ratio'
                            placeholder='Enter figure'
                            type='number'
                            error={errors.rightRatio?.message}
                            onChangeInput={(e) => [setValue('rightRatio', e.target.value), setError('rightRatio', {message: ''})]}
                            value={getValues('rightRatio')}
                        />
                    </div>
                    
                    <div className='flex w-full space-x-5'>
                        <div className='w-2/3'>
                            <InputField
                            placeholder='Enter figure'
                            type='number'
                            label='Tenor'
                            error={errors.tenor?.message}
                            onChangeInput={(e) => [setValue('tenor', e.target.value), setError('tenor', {message: ''})]}
                            value={getValues('tenor')}
                            />
                        </div>
                        <div className='w-1/3'>
                            <SelectField
                            placeholder="Select"
                            label='tenor'
                            labelClass="opacity-0"
                            error={errors.tenorIn?.message}
                            onChangeInput={(e) => [setValue('tenorIn', e.target.value), setError('tenorIn', {message: ''})]}
                            value={getValues('tenorIn') ?? 'months'}
                            options={DurationTypes}
                            />
                        </div>
                    </div>
                    <div className='flex w-full space-x-5'>
                        <div className='w-2/3'>
                            <InputField
                            placeholder='Enter figure'
                            type='number'
                            label='Offer Price'
                            error={errors.offerPrice?.message}
                            onChangeInput={(e) => [setValue('offerPrice', e.target.value), setError('offerPrice', {message: ''})]}
                            value={getValues('offerPrice')}
                            />
                        </div>
                        <div className='w-1/3'>
                            <SelectField
                            placeholder='Select'
                            label='currency'
                            labelClass="opacity-0"
                            error={errors.offerPriceCurrency?.message}
                            onChangeInput={(e) => [setValue('offerPriceCurrency', e.target.value), setError('offerPriceCurrency', {message: ''})]}
                            value={getValues('offerPriceCurrency') ?? 'NGN'}
                            options={CurrencyTypes}
                            />
                        </div>
                    </div>
                    <div>
                        <InputField
                            label='Series'
                            placeholder='Enter series'
                            error={errors.series?.message}
                            onChangeInput={(e) => [setValue('series', e.target.value), setError('series', {message: ''})]}
                            value={getValues('series')}
                        />
                    </div>
                    <div>
                        <InputField
                            label='Offer Open Date'
                            type='date'
                            error={errors.offerOpenDate?.message}
                            onChangeInput={(e) => [setValue('offerOpenDate', e.target.value), setError('offerOpenDate', {message: ''})]}
                            value={getValues('offerOpenDate')}
                        />
                    </div>
                    <div>
                        <InputField
                            label='Offer Close Date'
                            type='date'
                            error={errors.offerCloseDate?.message}
                            onChangeInput={(e) => [setValue('offerCloseDate', e.target.value), setError('offerCloseDate', {message: ''})]}
                            value={getValues('offerCloseDate')}
                        />
                    </div>
                    </div>
                    <div className="mt-4">
                        <h1>Issuing Houses </h1>
                        <p className='text-light-black text-[13px] mt-1 font-[450]'>The issuing houses involved in this product</p>
                        <div className='w-full px-5 py-2 min-h-[8em] gap-4 mt-2 border border-[#E7E7E7] font-[450] bg-white rounded-[5px] grid lg:grid-cols-3 items-baseline'>
                  {issuingHouses.map((item, index) => (
                    onEditIssuingHouse === index ? (
                      <div key={index} className='flex relative w-full space-x-3 text-[14px] items-center justify-between'>
                    <input 
                      onKeyDown={handleKeyDown} 
                      value={issuingHouse}
                      onInput={(e) => setIssuingHouse(e.currentTarget.value)}
                      className='w-full border-b border-[#E7E7E7] h-fit p-2 cursor-pointer focus:outline-none' placeholder='Enter issuing house'
                    />
                    {item && <button className='absolute right-0 z-10 pr-3 bg-white'  onClick={() => updateIssuingHouse(index)} type='button'><Check size={18}/></button>}
                  </div>
                    ) : (
                      <div key={index} className='flex relative w-full space-x-3 text-[14px] items-center justify-between'>
                        <div onClick={()=> {setIssuingHouse(item); setOnEditIssuingHouse(index)}} className='w-full border relative border-[#E7E7E7] rounded-[7px] h-fit p-2 cursor-pointer overflow-x-hidden capitalize'><span className='overflow-x-auto text-nowrap'>{item}</span></div>
                        <button className='absolute right-[0.5px] z-10 pr-3 bg-white' type='button' onClick={() => setIssuingHouses(issuingHouses.filter((_, i) => i !== index))}>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.8538 12.646C12.9002 12.6925 12.9371 12.7476 12.9622 12.8083C12.9874 12.869 13.0003 12.9341 13.0003 12.9998C13.0003 13.0655 12.9874 13.1305 12.9622 13.1912C12.9371 13.2519 12.9002 13.3071 12.8538 13.3535C12.8073 13.4 12.7522 13.4368 12.6915 13.462C12.6308 13.4871 12.5657 13.5001 12.5 13.5001C12.4343 13.5001 12.3693 13.4871 12.3086 13.462C12.2479 13.4368 12.1927 13.4 12.1463 13.3535L8.00003 9.20666L3.85378 13.3535C3.75996 13.4474 3.63272 13.5001 3.50003 13.5001C3.36735 13.5001 3.2401 13.4474 3.14628 13.3535C3.05246 13.2597 2.99976 13.1325 2.99976 12.9998C2.99976 12.8671 3.05246 12.7399 3.14628 12.646L7.29316 8.49979L3.14628 4.35354C3.05246 4.25972 2.99976 4.13247 2.99976 3.99979C2.99976 3.86711 3.05246 3.73986 3.14628 3.64604C3.2401 3.55222 3.36735 3.49951 3.50003 3.49951C3.63272 3.49951 3.75996 3.55222 3.85378 3.64604L8.00003 7.79291L12.1463 3.64604C12.2401 3.55222 12.3674 3.49951 12.5 3.49951C12.6327 3.49951 12.76 3.55222 12.8538 3.64604C12.9476 3.73986 13.0003 3.86711 13.0003 3.99979C13.0003 4.13247 12.9476 4.25972 12.8538 4.35354L8.70691 8.49979L12.8538 12.646Z" fill="#888888"/>
                        </svg>
                        </button>
                    </div>
                    )
                  ))}
                  { onEditIssuingHouse === null && <div className='flex relative w-full space-x-3 text-[14px] items-center justify-between'>
                    <input 
                      onKeyDown={handleKeyDown} 
                      value={issuingHouse}
                      onInput={(e) => setIssuingHouse(e.currentTarget.value)}
                      className='w-full border-b border-[#E7E7E7] h-fit p-2 cursor-pointer focus:outline-none' placeholder='Enter issuing house'
                    />
                    {issuingHouse && <button className='absolute right-0 z-10 pr-3 bg-white' onClick={addNewIssuingHouse} type='button'><Check size={18}/></button>}
                  </div>}
                </div>
                {errors.issuingHouses && <p className="my-1 text-sm text-red-500 label">{errors.issuingHouses.message}</p>}
                </div>
            </form>
        </FullscreenModalWrapper>}
        </div>
    );
}

export default EditProduct