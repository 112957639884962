import { useState, useEffect } from "react";
import AuthLayout from "../../components/layouts/auth"
import ApiRequest from "../../lib/ApiCall";
import { numberWithCommas } from "../../lib/helper-support";

const Dashboard = () => {

    type ProductType = {
        status: string;
        issuer: string;
        type: string;
        amount: string|number,
        interestRate: string|number,
        series: string,
        offerOpenDate:string,
        offerCloseDate:string,
        issuingHouses:string[],
        tenor: string,
        tenorIn: string,  
        amountCurrency: string,
        postedBy: string,
        uuid: string,
    }

    interface OverviewType{
        latestProducts: ProductType [],
        totalMembers:string,
        totalProducts:string,
        totalApprovedMembers:string,
        totalPublishedProducts:string,
    }

    const [overview, setOverview] = useState<OverviewType>({
        
    } as OverviewType);

    const getOverview = async () => {
        const request = await ApiRequest({endpoint: 'dashboard', method: 'GET'});
        request.status === '200' && setOverview(request.data);
    }

    useEffect(() => {getOverview()}, []);
    
    return(
        <AuthLayout>
            <div>
                <h1 className="page-title">Dashboard</h1>
                <div className="grid gap-4 mt-5 lg:grid-cols-4">
                    <div className="bg-black text-white p-5 rounded-[10px] shadow">
                        <span className="font-light">Total members</span>
                        <h3 className="font-[900] text-[1.5em]">{overview.totalMembers}</h3>
                    </div>
                    <div className=" text-black p-5 rounded-[10px] border bg-gray-50">
                        <span className="font-light">Approved members</span>
                        <h3 className="font-[900] text-[1.5em]">{overview.totalApprovedMembers}</h3>
                    </div>
                    <div className=" text-black p-5 rounded-[10px] border bg-gray-50">
                        <span className="font-light">Total products</span>
                        <h3 className="font-[900] text-[1.5em]">{overview.totalProducts}</h3>
                    </div>
                    <div className=" text-black p-5 rounded-[10px] border bg-gray-50">
                        <span className="font-light">Published products</span>
                        <h3 className="font-[900] text-[1.5em]">{overview.totalPublishedProducts}</h3>
                    </div>
                </div>
                <div>
                    <h1 className="mt-10">Latest Products</h1>
                </div>
                <div className='relative overflow-x-auto'>
                    <table className='mt-1 text-tertiary font-[500] text-[14px] w-full relative z-10'>
                        <thead>
                            <tr className='border-b border-[#E6EAF0] bg-[#fafafa]'>
                                <td className="px-4 py-3">Posted By</td>
                                <td className="px-4 py-3">Issuer</td>
                                <td className="px-6 py-3">Type</td>
                                <td className="px-6 py-3">Amount</td>
                                <td className="px-6 py-3 text-center">Interest Rate</td>
                                <td className="px-6 py-3">Tenor</td>
                                <td className="px-6 py-3">Status</td>
                            </tr>
                        </thead>
                        <tbody className="relative">
                            {overview.latestProducts?.map((product, i) => (
                                <tr key={i} className='border-b border-[#E6EAF0]'>
                                    <td className="px-4 py-3 capitalize">{product.postedBy}</td>
                                    <td className="px-4 py-3">{product.issuer}</td>
                                    <td className="px-6 py-3">{product.type}</td>
                                    <td className="px-6 py-3">{product.amountCurrency} {numberWithCommas(product.amount)}</td>
                                    <td className="px-6 py-3 text-center">{product.interestRate}</td>
                                    <td className="px-6 py-3 capitalize">{product.tenor} {product.tenorIn}</td>
                                    <td className={`px-6 py-3 text-xs uppercase ${product.status === 'published' ? 'text-green-800' : 'text-yellow-800'}`}>{product.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </AuthLayout>
    )
}

export default Dashboard