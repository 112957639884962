import { Eye, EyeOff } from "lucide-react";
import { ReactNode, useState } from "react";

type onChangeInputFunc = (e:React.ChangeEvent<HTMLInputElement>) => void

type InputTypeLabel = 'text' | 'email' | 'number' | 'password' | 'date';



type InputType = {
    placeholder?:string,
    type?: 'text' | 'email' | 'number' | 'password' | 'date',
    label?: string,
    id?: string,
    value?:string,
    error?:string|null,
    disabled?:boolean,
    onChangeInput?: onChangeInputFunc,
    inputClass?:string,
}

const InputField = ({
    placeholder,
    type = 'text',
    label,
    id,
    value,
    error,
    onChangeInput,
    disabled = false,
    inputClass,
} : InputType) => {
    const Id = id ?? label;

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const [inputType, setInputType] = useState<InputTypeLabel>(type);

    const onTogglePassword = () => {
        setShowPassword(!showPassword)
        showPassword ? setInputType('password') : setInputType('text');
    }

    return(
        <label htmlFor={Id} className="label">
            <span>{label}</span>
            <div className="relative flex items-center">
                <input 
                    placeholder={placeholder ?? `Enter ${label}`} 
                    type={inputType}
                    className={`input border-[#E8ECEF] border ${inputClass} ${error && 'border-red-500'} ${disabled && 'text-light-black'}`}
                    onInput={onChangeInput}
                    value={value}
                    disabled={disabled}
                />
                {type === 'password' && 
                    <div className="absolute cursor-pointer right-5" onClick={onTogglePassword}>
                        {showPassword ? <EyeOff size={18}/> : <Eye size={18}/>}
                    </div>
                }
            </div>
            {error && <p className="my-1 text-sm text-red-500 label">{error}</p>}
        </label>
    );
}

export default InputField;