import { useEffect, useState } from "react";
import PaginationType from "../../../../types/pagination-type";
import ApiRequest from "../../../../lib/ApiCall";
import SingleProduct from "../../product/single-product";
import { numberWithCommas } from "../../../../lib/helper-support";
import { SuccessFlash } from "../../../../lib/flash-notification";

type ProductType = {
    status: string;
    issuer: string;
    type: string;
    amount: string|number,
    interestRate: string|number,
    series: string,
    offerOpenDate:string,
    offerCloseDate:string,
    issuingHouses:string[],
    tenor: string,
    tenorIn: string,  
    amountCurrency: string,
    postedBy: string,
    uuid: string,
}

interface MemberProductType {
    memberId: string
}

const MemberProduct = ({memberId} : MemberProductType) => {
    const [products, setProducts] = useState<ProductType[]>([]);
    
    const [pageData, setPageData] = useState<PaginationType>({} as PaginationType);
    
    const getProduct = async () => {
    
        const request = await ApiRequest({endpoint:`/member/${memberId}/products`, method:'GET'});
        if (request.status !== '200') return;
        setProducts(request.data.items);
        setPageData(request.data);
    }

    useEffect(() => {getProduct()}, []);

    const onProductUpdate =  (product:any) => {
        setProducts(products.map((item) => item.uuid === product.uuid ? {...product} : item));   
    }
    const onProductDelete =  (product:any) => {
        setProducts(products.filter((item) => item.uuid !== product.uuid));
        setPageData({...pageData, total: pageData.total - 1});
        SuccessFlash('Product deleted successfully')
    }
    return(<>
    <h4 className="flex items-center mt-10 space-x-2 font-bold label">
                        <span className="p-3 text-white rounded bg-primary">{pageData.total}</span>
                        <span>Products</span>
                    </h4>
                    <div className='relative mt-2 overflow-x-auto'>
                        <table className='text-tertiary font-[500] text-[14px] w-full relative z-10'>
                            <thead>
                                <tr className='border-b border-[#E6EAF0] bg-[#fafafa]'>
                                    <td className="px-4 py-3">Issuer</td>
                                    <td className="px-6 py-3">Type</td>
                                    <td className="px-6 py-3">Amount</td>
                                    <td className="px-6 py-3 text-center">Interest Rate</td>
                                    <td className="px-6 py-3">Tenor</td>
                                    <td className="px-6 py-3">Status</td>
                                    <td className="px-6 py-3 text-center">Action</td>
                                </tr>
                            </thead>
                            <tbody className="relative">
                                {products.map((product, i) => (
                                    <tr key={i} className='border-b border-[#E6EAF0]'>
                                        <td className="px-4 py-3">{product.issuer}</td>
                                        <td className="px-6 py-3">{product.type}</td>
                                        <td className="px-6 py-3">{product.amountCurrency} {numberWithCommas(product.amount)}</td>
                                        <td className="px-6 py-3 text-center">{product.interestRate}</td>
                                        <td className="px-6 py-3">{product.tenor} {product.tenorIn}</td>
                                        <td className={`px-6 py-3 text-xs ${product.status === 'published' ? 'text-green-800' : 'text-yellow-800'} uppercase`}>{product.status}</td>
                                        <td className="px-6 py-3 text-center">
                                            {<SingleProduct productId={product.uuid} onProductUpdate={onProductUpdate} onProductDelete={onProductDelete}/>}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
    </>);
}

export default MemberProduct