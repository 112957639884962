import { useEffect, useState } from "react";
import PaymentTypeForm from "./payment-type-form";
import ApiRequest from "../../../../../../lib/ApiCall";
import { set } from "react-hook-form";
import { FilePenLine } from "lucide-react";
import PaginationType from "../../../../../../types/pagination-type";
import { numberWithCommas } from "../../../../../../lib/helper-support";

interface PageType {
    fetchData: boolean,
}
const PaymentType = ({fetchData} : PageType) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    
    interface PaymentType{
        name: string,
        amount: string,
        description: string,
        duration: string,
        isRecurringPayment: boolean,
        isOnboardPayment: boolean,
        uuid: string,
    }
    
    const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([]);
    const [paymentType, setPaymentType] = useState<PaymentType>({} as PaymentType);
    const [pageData, setPageData] = useState<PaginationType>({} as PaginationType);

    const getTypes = async () => {
        const request = await ApiRequest({endpoint: 'payment-type', method: 'GET'});
       request.status === '200' && setPaymentTypes(request.data.items);
       console.log(request.data.items);
       request.status === '200' && setPageData(request.data);
       
    }
    useEffect(() => { fetchData && getTypes() }, []);

    const onSuccess = (data:any) => {
       isEdit ? setPaymentTypes(paymentTypes.map((type) => type.uuid === data.uuid ? data : type)) : setPaymentTypes([...paymentTypes, data]);
        setOpenModal(false);
    }

    const getSinglePaymentType = (uuid:string) => {
        const paymentType = paymentTypes.find((type) => type.uuid === uuid);
        if ( ! paymentType ) return;
        setPaymentType(paymentType);
        setIsEdit(true);
        setOpenModal(true);
    }
    return (
        <div>
            <div className="flex justify-between mt-5">
            <div className=" text-black p-10 rounded-[1em] border bg-gray-50">
                        <span className="font-light">Total Payment Types</span>
                        <h3 className="font-[900] text-[1.5em]">{pageData?.total}</h3>
                    </div>
                <button onClick={() => {setOpenModal(true); setIsEdit(false); setPaymentType({} as PaymentType)}} className="pryBtn w-fit" type="button">Add new Payment Type</button>
            </div>
            <div className='relative mt-5 overflow-x-auto'>
                    <table className='mt-1 text-tertiary font-[500] text-[14px] w-full relative z-10'>
                        <thead>
                            <tr className='border-b border-[#E6EAF0] bg-[#fafafa]'>
                                <td className="px-4 py-3">Name</td>
                                <td className="px-4 py-3">Amount(NGN)</td>
                                <td className="px-6 py-3">Description</td>
                                <td className="px-6 py-3 text-center">Recurring Payment</td>
                                <td className="px-6 py-3 text-center">Included on Registration</td>
                                <td className="px-6 py-3">Action</td>
                            </tr>
                        </thead>
                        <tbody className="relative">
                            {paymentTypes.map((type, i) => (
                                <tr key={i} className="relative border-b border-[#E6EAF0] bg-[#fafafa]">
                                    <td className="px-4 py-3">{type.name}</td>
                                    <td className="px-4 py-3">{numberWithCommas(type.amount)}</td>
                                    <td className="px-6 py-3">{type.description}</td>
                                    <td className="px-6 py-3 text-center">{type.isRecurringPayment ? 'Yes' : 'No'}</td>
                                    <td className="px-6 py-3 text-center">{type.isOnboardPayment ? 'Yes' : 'No'}</td>
                                    <td className="px-6 py-3">
                                        <button onClick={() => getSinglePaymentType(type.uuid)} type="button" className="pryBtnOutline">
                                            <FilePenLine size={18}/>
                                            <span>Edit</span>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <PaymentTypeForm 
                    openModal={openModal}
                    onSuccess={onSuccess}
                    isEdit={isEdit}
                    onCloseModal={() => setOpenModal(false)}
                    data={paymentType}
                />
        </div>
    );

}

export default PaymentType