import CryptoJS from 'crypto-js';

const app_key : string | undefined = process.env.REACT_APP_KEY;

const SmoothScroll = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
}

const encrypt = (data:string) => {
  return CryptoJS.AES.encrypt(data, app_key ?? '').toString();
}

const decrypt = (data:string) => {
  return CryptoJS.AES.decrypt(data, app_key ?? '').toString(CryptoJS.enc.Utf8);
}

const EmptyFormInput = (formSchema: any) => {
    return Object.keys(formSchema.fields).reduce((acc:any, item:any) => {
        console.error();
        switch(formSchema.fields[item].type){
            case 'boolean':
                acc[item] = false;
              break;
              default:
                  acc[item] = '';
        }
        return acc;
      }, {});
}

const numberWithCommas = (input: string | number, withDecimal = false, onlyDecimal = false) => {  
  if (withDecimal){
      let hasDecimal = input.toString().split('').indexOf('.') !== -1;

      if (! hasDecimal )  input += '.00';
  }
  
  let parts = input.toString().split('.');

  if ( withDecimal && onlyDecimal ){
      return parts[1].toString();
  }
  
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (! withDecimal) return parts[0];
  
  return parts.join('.');
}


const Naira = '₦';

const DurationTypes = ['Months', 'Years'].map((item: any) => ({value: item.toLocaleLowerCase(), name: item}));
const TransactionTypes = ['Debt Capital Market', 'Equity Capital Markets', 'Financial Advisory'].map((item: any) => ({value: item, name: item}));
const SecurityTypes = [
    'Commercial Paper', 'Public Bond', 
    'Private Bond', 'Rights Issue', 
    'Follow-on Offer', 'Initial Public Offer ("IPO")',
    'Merger', 'Acquisition', 'Divestment'
].map((item: any) => ({value: item, name: item}));
const RegulatorTypes = ['FMDQ Securities Exchange Limited', 'Securities & Exchange Commission'].map((item: any) => ({value: item, name: item}));
const TransactionRolesTypes = ['Lead', 'Joint'].map((item: any) => ({value: item, name: item}));
const CurrencyTypes = [
  {
    name: `${Naira}(NGN)`,
    value: 'NGN',
  }];

  const RolesOptionsType = ['admin', 'user'].map((item: any) => ({value: item, name: item}));

  const generateRandomNumber = (length:number) => {
    const min = Math.pow(10, length - 1);
    const max = Math.pow(10, length) - 1;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

export {
    SmoothScroll,
    EmptyFormInput,
    numberWithCommas,
    generateRandomNumber,
    encrypt,
    decrypt,
    TransactionTypes,
    SecurityTypes,
    RegulatorTypes,
    TransactionRolesTypes,
    CurrencyTypes,
    DurationTypes,
    RolesOptionsType,
}