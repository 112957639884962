import { Link } from "react-router-dom"
import AuthLayout from "../../../components/layouts/auth"
import { Eye, FilePenLine } from "lucide-react"
import { useEffect, useState } from "react";
import ApiRequest from "../../../lib/ApiCall";
import PaginationType from "../../../types/pagination-type";
import UserForm from "./user-form";

const Users = () => {

    interface UserType{
        email:string,
        name:string,
        role:string,
        uuid: string,
    }

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const [pageData, setPageData] = useState<PaginationType>({} as PaginationType);

    const [users, setUsers] = useState<UserType[]>([]);

    const [user, setUser] = useState<UserType>({} as UserType);

    const getUsers = async () => {
        const request = await ApiRequest({endpoint: 'user', method: 'GET'});

        if (request.status !== '200') return;
        setUsers(request.data.items);
        setPageData(request.data);
    }

    useEffect(()=> {getUsers()}, [])

    const onSuccess = (data:any) => {
        isEdit ? setUsers(users.map((user) => user.uuid === data.uuid ? data : user)) : setUsers([...users, data]);
         setOpenModal(false);
     }

     const getSingleUser = (uuid:string) => {
        const user = users.find((user) => user.uuid === uuid);
        if ( ! user ) return;
        setUser(user);
        setIsEdit(true);
        setOpenModal(true);
    }

    return (
        <AuthLayout>
            <div>
                <div className="flex justify-between">
                    <div>
                        <h1 className="page-title">Users</h1>
                        <p className="text-sm italic font-light text-gray-700">total users {pageData.total}</p>
                    </div>
                    <button onClick={() => {setOpenModal(true); setIsEdit(false); setUser({} as UserType)}} className="pryBtn w-fit" type="button">Add new user</button>
                </div>
                <div className='relative overflow-x-auto'>
                    <table className='mt-5 text-tertiary font-[500] text-[14px] w-full relative z-10'>
                        <thead>
                            <tr className='border-b border-[#E6EAF0] bg-[#fafafa]'>
                                <td className="px-4 py-3">Name</td>
                                <td className="px-6 py-3">Email</td>
                                <td className="px-6 py-3">Role</td>
                                <td className="px-6 py-3 text-center">Action</td>
                            </tr>
                        </thead>
                        <tbody className="relative">
                            {users.map((user, i) => (
                                <tr key={i} className='border-b border-[#E6EAF0]'>
                                <td className="px-4 py-3 capitalize">{user.name}</td>
                                <td className="px-6 py-3">{user.email}</td>
                                <td className="px-6 py-3">{user.role}</td>
                                <td className="px-6 py-3 text-center capitalize">
                                    <button onClick={() => getSingleUser(user.uuid)} type="button" className="pryBtnOutline">
                                            <FilePenLine size={18}/>
                                            <span>Edit</span>
                                        </button>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <UserForm 
                    openModal={openModal}
                    onSuccess={onSuccess}
                    isEdit={isEdit}
                    onCloseModal={() => setOpenModal(false)}
                    data={user}
                />
            </div>
        </AuthLayout>
    )
}

export default Users