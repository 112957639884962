import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import Dashboard from '../pages/auth/dashboard';
import Members from '../pages/auth/member/members';
import Products from '../pages/auth/product/products';
import Users from '../pages/auth/user/users';
import SingleMember from '../pages/auth/member/single-member';
import EditMember from '../pages/auth/member/edit-member';
import PushMessage from '../pages/auth/message';
import Payments from '../pages/auth/payment/payment';
import Account from '../pages/auth/account';
import { AuthToken } from '../lib/auth/auth-store';

type RouteType = {
  path: string;
  element: React.ReactNode;
};

const routes: RouteType[] = [
  { path: '/', element: <Dashboard /> },
  { path: '/members', element: <Members/>},
  { path: '/members/:id', element: <SingleMember/>},
  { path: '/members/:id/edit', element: <EditMember/>},
  { path: '/products', element: <Products/>},
  { path: '/users', element: <Users/>},
  { path: '/message', element: <PushMessage/>},
  { path: '/payment', element: <Payments/>},
  { path: '/account', element: <Account/>},
];

// Check if authenticated
const middleware = () => {
  const isAuthenticated = Boolean(!!AuthToken());
  return routes.map((route) => ({
    path: route.path,
    element: isAuthenticated ? route.element : <Navigate to="/login" />
  }));
}

const AuthRoutes : RouteObject[] = middleware();

export default AuthRoutes;
