import { Ban, ChevronLeft, UserRoundCheck, UserRoundPen } from "lucide-react"
import AuthLayout from "../../../components/layouts/auth"
import { Link, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import ApiRequest from "../../../lib/ApiCall"
import NotFound from "../../../components/not-found"
import DefaultModal from "../../../components/modal/default-modal"
import MemberProduct from "./partials/product"
import MemberPayment from "./partials/payment"
import { SuccessFlash } from "../../../lib/flash-notification"

const SingleMember = () => {    
    const {id} = useParams<{id:string}>();

    type representativesType = {
        name: string,
        position: string
        mobile_number:string,
        email_address:string,
        is_primary: boolean
    }

    type MemberType = {
        uuid:string,
        contactRepresentatives: representativesType[],
        secRegistrationNumber:string,
        status:string,
        nameInstitution: string,
        registeredAddress: string,
        contactAddress: string,
        
    };

    const [member, setMember] = useState<MemberType>({} as MemberType);

    const [pageLoading, setPageLoading] = useState(true);

    const getMember = async () => {
        const request = await ApiRequest({endpoint:`/member/${id}`, method:'GET'});
        setPageLoading(false);
        if (request.status !== '200') return;
        setMember(request.data);
    }

    useEffect(() => {getMember()}, []);

    const [loadingApproval, setLoadingApproval] = useState<boolean>(false);
    const approveAccount = async () => {
        setLoadingApproval(true);
        const request = await ApiRequest({endpoint: '/member/approval', method:'POST', payload:{
            memberId: member.uuid,
            status: 'approved',
        }});
        setLoadingApproval(false);
        if (request.status !== '200') return;
        setMember(request.data);
        SuccessFlash('Account approved successfully');
    }

    const onActivation = async () => {
        setLoadingApproval(true);
        const request = await ApiRequest({endpoint: `/member/${member.uuid}/account-Activation`, method:'POST'});
        if (request.status !== '200') return;
        setMember(request.data);
        SuccessFlash(`Account ${member.status === 'active' ? 'deactivated' : 'activated'} successfully`);
    }

    return (
        <AuthLayout>
            <div>
                {(! pageLoading && ! member.uuid) && <NotFound route="/members"/>}
                {member.uuid && (
                    <div>
                    <div className="items-center justify-between space-y-5 lg:flex lg:space-y-0">
                        <div className="flex items-center space-x-2">
                            <Link to={'/members'}><ChevronLeft/></Link>
                            <h1 className="text-sm page-title">{member.nameInstitution}</h1>
                        </div>
                        <div className="justify-end mt-5 lg:flex lg:mt-0">
                            <div className="items-center space-y-2 label lg:flex lg:space-x-4 lg:space-y-0">
                                <div className="text-xs">Status:<p style={{color: member.status === 'active' ? 'green' : 'orange'}} className="mt-0 font-bold capitalize">{member.status}</p></div>
                                {member.status !== 'pending initial payment' &&<div className="flex space-x-4">
                                    <Link to={`/members/${id}/edit`} className="pryBtnOutline text-wrap w-fit" type="button">
                                        <UserRoundPen size={18}/>
                                        <span>Edit account</span>
                                    </Link>
                                    {member.status === 'awaiting approval' && <button disabled={loadingApproval} onClick={approveAccount} className={`${loadingApproval && 'opacity-[0.5]'} pryBtn w-fit`} type="button">
                                        <UserRoundCheck size={18}/>
                                        <span>Approve Account</span>
                                    </button>}
                                    {member.status === 'active' && <DefaultModal
                                        buttonTriggerClassIcon={<Ban size={18}/>}
                                        modalButtonTriggerText="Deactivate Account"
                                        description="Are you sure you want to deactivate this account?"
                                        onConfirmationAction={onActivation}
                                        buttonTriggerClass='pryBtn bg-red-800'
                                    />}
                                    {member.status === 'inactive' && <DefaultModal
                                        buttonTriggerClassIcon={<UserRoundCheck size={18}/>}
                                        modalButtonTriggerText="Activate Account"
                                        description="Are you sure you want to activate this account?"
                                        onConfirmationAction={onActivation}
                                        buttonTriggerClass='pryBtn bg-green-800'
                                    />}
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="grid gap-4 mt-10 lg:grid-cols-2">
                        <div>
                            <div className="grid gap-4 lg:grid-cols-2">
                                <div>
                                    <p className="label">Institution Name</p>
                                    <h3 className="font-bold capitalize">{member.nameInstitution}</h3>
                                </div>
                                <div>
                                    <p className="label">SEC Registration Number</p>
                                    <h3 className="font-bold">{member.secRegistrationNumber}</h3>
                                </div>
                            </div>
                            <div className="mt-4">
                                <p className="label">Registered Address</p>
                                <h3 className="font-bold">{member.registeredAddress}</h3>
                            </div>
                            <div className="mt-4">
                                <p className="label">Contact Address</p>
                                <h3 className="font-bold">{member.contactAddress}</h3>
                            </div>
                            <div className="mt-4">
                                <p className="font-bold underline label text-secondary">Contact details of Representatives</p>
                            </div>
                            {member.contactRepresentatives.map((contact, index)=>(
                                <div key={index} className={`mt-4 ${contact.is_primary && 'p-4 border rounded shadow'}`}>
                                    <p className="font-bold label">
                                        Contact {index+1}:&nbsp;
                                        {contact.is_primary && <span className="p-1 text-sm italic font-light text-white rounded bg-secondary">primary</span>}
                                    </p>
                                    <div className="grid gap-4 mt-1 lg:grid-cols-2">
                                        <div>
                                            <p className="label">Name</p>
                                            <h3 className="font-bold">{contact.name}</h3>
                                        </div>
                                        <div>
                                            <p className="label">Position</p>
                                            <h3 className="font-bold">{contact.position}</h3>
                                        </div>
                                        <div>
                                            <p className="label">Phone number</p>
                                            <h3 className="font-bold">{contact.mobile_number}</h3>
                                        </div>
                                        <div>
                                            <p className="label">Email</p>
                                            <h3 className="font-bold">{contact.email_address}</h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="px-5">
                            <MemberPayment memberId={member.uuid} />
                        </div>
                    </div>
                    <MemberProduct memberId={member.uuid}/>
                </div>
                )}
            </div>
        </AuthLayout>
    )
}

export default SingleMember